import React from "react";
import ReactDOM from "react-dom/client";
import "./indexlanding.css";

import logo from "./components/ui/navbar_assets/logoMobile.png";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<div className="h-[100vh] flex" style={{background: 'radial-gradient(circle, rgba(16,23,31,1) 15%, rgba(0,0,0,1) 100%)'}}>
  <div className="ml-auto mr-auto text-center mt-auto mb-auto" >
  <img src={logo} className="w-[300px] ml-auto mr-auto" />
  <p className="text-4xl text-white font-semibold tracking-widest">COMING <span className="text-warning font-bold">SOON</span></p>
  </div>
  </div>)
